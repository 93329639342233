import React, {useEffect, useState} from "react";
import {Col, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label} from "reactstrap";
import InputMask from "react-input-mask";

import {Validator} from '../../../data/forms/validators/Validator';

let idCount = 1;

export const AppInput = ({
                              label,
                              name,
                              placeholder,
                              value,
                              defaultModel,
                              help,
                              startAdornment,
                              endAdornment,
                              prefix,
                              suffix,
                              type = "text",
                              required = false,
                              disabled = false,
                              readOnly = false,
                              inline = false,
                              showValid = true,
                              mask,
                              showMaskChar = false,
                              min,
                              max,
                              step = 1,
                              minLength,
                              maxLength,
                              pattern,
                              validMsg,
                              invalidMsg,
                              onValidate,
                              onChange
                          }) => {

    const identifier = 'input-' + idCount++;
    const [validation, setValidation] = useState({valid: true, errors: []});
    const [blur, setBlur] = useState(false);
    const [_value, _setValue] = useState(value);

    const validate = (value) => {
        const validation = Validator.validate(value, [
            Validator.REQUIRED(required),
            Validator.MIN(min),
            Validator.MAX(max),
            Validator.MIN_LENGTH(minLength),
            Validator.PATTERN(pattern)
        ], invalidMsg);

        onValidate && onValidate(validation.valid, name);

        return validation;
    };

    const handleChange = (event) => {
        const validation = validate(event.target.value);
        setValidation(validation);
        _setValue(event.target.value);
        onChange && onChange(event.target.value, name);
    };

    const handleBlur = (event) => {
        setBlur(true);
        handleChange(event);
    };

    useEffect(() => {
        validate(value);
        // eslint-disable-next-line
    }, []);

    const validClass = blur && validation.valid && !!_value && showValid ? 'is-valid' : '';
    const invalidClass = blur && !validation.valid ? 'is-invalid' : '';

    return (
        <>
            <FormGroup row>
                {
                    label && (
                        <Label for={identifier} sm={inline ? 2 : 12}>
                            {label} {required && (<span className={'text-danger'}>*</span>)}
                        </Label>
                    )
                }

                <Col sm={inline && label ? 10 : 12}>
                    <InputGroup>
                        {startAdornment && startAdornment}
                        {prefix && (<InputGroupText>{prefix}</InputGroupText>)}
                        {
                            !mask ? (
                                <Input
                                    id={identifier}
                                    valid={blur && validation.valid && !!_value && showValid}
                                    invalid={blur && !validation.valid}
                                    placeholder={placeholder ? `${placeholder}${required ? ' *' : ''}` : label}
                                    disabled={disabled}
                                    type={type}
                                    mask={mask}
                                    defaultValue={value ? value : defaultModel ? defaultModel[name] : null}
                                    name={name}
                                    step={step}
                                    maxLength={maxLength}
                                    readOnly={readOnly}
                                    onChange={handleChange}
                                    onBlur={handleBlur}/>
                            ) : (
                                <InputMask
                                    id={identifier}
                                    className={`form-control ${validClass} ${invalidClass}`}
                                    placeholder={placeholder ? `${placeholder}${required ? ' *' : ''}` : label}
                                    disabled={disabled}
                                    mask={mask}
                                    maskChar={showMaskChar ? true : null}
                                    defaultValue={value ? value : defaultModel ? defaultModel[name] : null}
                                    name={name}
                                    maxLength={maxLength}
                                    readOnly={readOnly}
                                    onChange={handleChange}
                                    onBlur={handleBlur}/>
                            )
                        }

                        {suffix && (<InputGroupText>{suffix}</InputGroupText>)}
                        {endAdornment && endAdornment}
                        {
                            blur && (!validation.valid || validMsg) && (
                                <FormFeedback valid={validation.valid}>
                                    {
                                        validation.valid && validMsg ? validMsg : validation.errors.join(' ')
                                    }
                                </FormFeedback>
                            )
                        }
                    </InputGroup>
                    {
                        help && (
                            <FormText>
                                {help}
                            </FormText>
                        )
                    }
                </Col>
            </FormGroup>
        </>
    );

}