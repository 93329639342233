
export const MinValidator = {
    validate: (value, min) => {
        return {
            valid: value === '' || value === null || value === undefined || (
                Number(value) >= Number(min)
            ),
            msg: 'The value must be greater than or equal to ' + min + '.'
        }
    }
};
