
export const RequiredValidator = {
    validate: (value, required) => {
        return {
            valid: !required ||
                   (   value !== '' &&
                       value !== null &&
                       value !== undefined &&
                       (!Array.isArray(value) || value.length > 0)
                   ),
            msg: 'This field is required.'
        }
    }
};
