
export const PatternValidator = {
    validate: (value, pattern) => {
        const regexp = new RegExp(pattern.PATTERN);

        return {
            valid: value === '' || value === null || value === undefined || (
                regexp.test(value)
            ),
            msg: pattern.MSG
        }
    }
};
