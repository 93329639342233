import React, {Fragment} from "react";
import {Footer} from "../layouts/Footer";
import {MainContent} from "../layouts/MainContent";

export const SimpleContainer = (props) => {
    const {children} = props;

    return (
        <Fragment>
            <MainContent>
                {children}
            </MainContent>
            <Footer></Footer>
        </Fragment>
    );
};
