import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "reactstrap";
import {changeModel, validate} from "../../data/forms/utils";
import {AppInput} from "../form/AppInput/AppInput";
import {PATTERN} from "../../data/forms/Pattern";
import {useTranslation} from "react-i18next";
import {Loader} from "react-loaders";
import SweetAlert from 'react-bootstrap-sweetalert';
import {contactUsService} from "../../data/services/contactUsService";
import {connect} from "react-redux";
import {setIsLoading} from "../../data/store/reducers/AppLoading";

const FormSection = (props) => {

    const {setIsLoading} = props;

    const {t} = useTranslation();

    const [errors, setErrors] = useState([]);
    const [model, setModel] = useState({});
    const [mailData, setMailData] = useState(null);
    const [fillData, setFillData] = useState({});
    const [alert, setAlert] = useState({
        show: false
    });

    useEffect(() => {
        contactUsService.getMessage().subscribe((data) => {
            setMailData(data.send_information);
            setIsLoading(false);
        });
    }, []);

    const registerControl = {
        onValidate: validate(errors, setErrors),
        onChange: changeModel(model, setModel),
        defaultModel: {}
    };

    const showAlert = (data) => {
        setAlert({
           show: true,
           ...data
        });
    };

    const handleAlertConfirm = () => {
        if(alert.type === "success") {
            //TODO evitar recargar la pagina y limpiar formulario
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
        setAlert({show: false});
    };

    const submit = () => {
        setIsLoading(true);
        contactUsService.postData({
            ...model,
            user_last_name: model.user_last_name
        }).subscribe(() => {
            setIsLoading(false);
            showAlert({
                title: 'Your request has been sent successfully',
                type: 'success'
            });
        }, errors => {
            setIsLoading(false);
            showAlert({
                title: 'We\'re sorry, something went wrong while sending your information. Please try again!',
                type: 'error'
            });
        });
    };

    const handleFormChanged = () => {
        console.log(model);
        setFillData({
            user_first_name: model.user_first_name,
            user_last_name: model.user_last_name,
            user_email: model.user_email
        });
    };

    return (
        <>
            {
                alert.show && (
                    <SweetAlert title={alert.title} show={alert.show}
                                type={alert.type} onConfirm={handleAlertConfirm}/>
                )
            }

            <Container fluid className={"app-form-section"}>
                <Form onChange={handleFormChanged}>
                    <Row>
                        <Col md={{offset: 1, size: 10}}
                             xl={{offset: 2, size: 8}}
                             xxl={{offset: 3, size: 6}}>
                            <hr/>
                            <Row>
                                <Col md="12" className={"mb-2"}>
                                    <p className={"text-center title"}>Email Form</p>
                                    <p className={"text-center subtitle"}>Please fill in the fields that are requested below</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{offset: 2, size: 8}}
                                     md={{offset: 3, size: 6}}
                                     className={"controls"}>
                                    <AppInput placeholder={'First name'}
                                              name={'user_first_name'}
                                              required
                                              invalidMsg={{required: 'Your first name is required.'}}
                                              {...registerControl}/>
                                    <AppInput placeholder={'Last name'}
                                              name={'user_last_name'}
                                              required
                                              {...registerControl}/>
                                    <AppInput placeholder={'Email'}
                                              name={'user_email'}
                                              required
                                              invalidMsg={{required: 'An email address is required.'}}
                                              pattern={PATTERN.EMAIL}
                                              {...registerControl}/>
                                    <AppInput placeholder={'Phone Number'}
                                              name={'user_phone'}
                                              invalidMsg={{required: 'A phone number is required.'}}
                                              required
                                              mask={'+1 (999) 999-9999'}
                                              pattern={PATTERN.US_PHONE}
                                              {...registerControl}/>
                                </Col>
                            </Row>
                            {
                                mailData && (
                                    <Row className={"mail-wrapper"}>
                                        <Col md={"12"}>
                                            <Row className={"mail-content"}>
                                                <div className={'mail-part destination'}>
                                                    <label>To :  </label>
                                                    <span className={'value'}>
                                                        {
                                                            mailData.list_email_to_send.map((item, key) => (
                                                                <Fragment key={key}>
                                                                    <a href={`mailto:${item.email}`}>{`${item.name} <${item.email}>`}</a>
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </span>
                                                </div>
                                                <div className={'mail-part d-flex'}>
                                                    <label>CC :  </label>
                                                    <span className={'value'}>
                                                      {fillData.user_email}
                                                    </span>
                                                </div>

                                                <div className={'mail-part d-flex'}>
                                                    <label>Subject :  </label>
                                                    <span className={'value'}>
                                                        {
                                                            fillData.user_first_name && fillData.user_last_name ? (
                                                                `${mailData.subject} on behalf of ${fillData.user_first_name} ${fillData.user_last_name}`
                                                            ) : (
                                                                mailData.subject
                                                            )
                                                        }

                                                    </span>
                                                </div>
                                                <div className={'mail-part body'}>
                                                    <p>{mailData.body}</p>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            }

                            <Row>
                                <Col sm={{offset: 3, size: 6}}
                                     md={{offset: 4, size: 4}}
                                     xxl={{offset: 5, size: 2}}>
                                    <Row>
                                        <Button color="primary"
                                                onClick={submit}
                                                disabled={errors.length > 0}
                                                className="mt-1">
                                            Submit
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Form>
            </Container>
        </>
    );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    setIsLoading: isLoading => dispatch(setIsLoading(isLoading))
});
export default connect(mapStateToProps, mapDispatchToProps)(FormSection);
