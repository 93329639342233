import {RequiredValidator} from './RequiredValidator'
import {MaxValidator} from './MaxValidator'
import {MinValidator} from './MinValidator'
import {MinLengthValidator} from './MinLengthValidator'
import {PatternValidator} from './PatternValidator'

export const Validator = {
    REQUIRED: (value) => { return {validator: RequiredValidator, value: value, name: 'required'} },
    MIN: (value) => { return {validator: MinValidator, value: value, name: 'min'} },
    MAX: (value) => { return {validator: MaxValidator, value: value, name: 'max'} },
    MIN_LENGTH: (value) => { return {validator: MinLengthValidator, value: value, name: 'minLength'} },
    PATTERN: (value) => { return {validator: PatternValidator, value: value, name: 'pattern'} },

    validate: (value, validations, messages) => {
        const errors = [];
        validations.forEach(validation => {
            if (validation.value !== null && validation.value !== undefined) {
                const result = validation.validator.validate(value, validation.value);
                if (!result.valid) {
                    if (messages && messages[validation.name]) {
                        errors.push(messages[validation.name]);
                    } else {
                        errors.push(result.msg);
                    }
                }
            }
        });
        return {
            valid: errors.length === 0,
            errors: errors
        }
    }
};
