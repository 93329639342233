
export const validate = (errors, setErrors) => {
    let _errors = errors;

    return (valid, name) => {
        if (!valid && _errors.indexOf(name) === -1) {
            _errors = [..._errors, name];
            setErrors(_errors);
        } else if (valid && errors.indexOf(name) >= 0) {
            _errors = _errors.filter(value => value !== name);
            setErrors(_errors);
        }
    }
};
