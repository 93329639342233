import {apiService} from "./apiService";

const resource = '/email'

export const contactUsService = {
    getMessage: () => {
        return apiService.post(`${resource}/message-to-signing`, {});
    },
    postData: (data) => {
        return apiService.post(`${resource}/user-signing`, data);
    }
}