
export const MinLengthValidator = {
    validate: (value, minLength) => {
        return {
            valid: value === '' || value === null || value === undefined || (
                value.length >= Number(minLength)
            ),
            msg: 'This field must contain at least ' + minLength + ' characters.'
        }
    }
};
