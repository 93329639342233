export const PATTERN = {
    USERNAME: {
        PATTERN: '^[A-z0-9Á-ú\\-._@]+$',
        MSG: 'This field must contain letters, numbers and (-._@).'
    },
    PASSWORD: {
        PATTERN: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.,;:_*\\-@]).*$',
        MSG: 'This field must contain lowercase, uppercase, numbers, and (.,;:_*-@).'
    },
    EMAIL: {
        PATTERN: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
        MSG: 'This field must contain an email valid address.'
    },
    US_PHONE: {
        PATTERN: '^\\+1 (\\([0-9]{3}\\) )[0-9]{3}-[0-9]{4}$',
        MSG: 'This field must contain a USA valid phone number.'
    },
    INTEGER: {
        PATTERN: '[0-9]*',
        MSG: 'This field must contain an integer value.'
    },
    IPV4ADDRESS: {
        PATTERN: '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)([/](3[0-2]|2[0-9]|1[0-9]|[0-9]))*$',
        MSG: 'This field must contain a valid IP address.'
    }
}
