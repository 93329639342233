import axios from "axios";
import {from, map} from "rxjs";

const getHeaders = (authorization = true) => {
    let headers = {};

    if (authorization) {
        headers['Authorization'] = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`;
    }

    return headers;
}

const getHttpClient = (options) => {
    const baseUrl = process.env.REACT_APP_API_URL_SERVER;

    const defaultOptions = {
        absolutePath: false,
        headers: {}
    };

    const _options = {
        ...defaultOptions,
        ...options
    }

    return axios.create({
        baseURL: !_options.absolutePath ? baseUrl : '',
        headers: {
            ..._options.headers,
            ...getHeaders(true)
        }
    });
};

const handleResponse = (options, response) => {
    const defaultOptions = {
        fullResponse: false
    };
    const _options = {
        ...defaultOptions,
        ...options
    }
    return _options.fullResponse ? response : response.data;
}

export const apiService = {
    get: (path, options) => {
    return from(getHttpClient(options).get(path)).pipe(map(handleResponse.bind(this, options)));
    },
    post: (path, data, options) => {
        return from(getHttpClient(options).post(path, data)).pipe(map(handleResponse.bind(this, options)));
    }
}
