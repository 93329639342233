
export const MaxValidator = {
    validate: (value, max) => {
        return {
            valid: value === '' || value === null || value === undefined || (
                Number(value) <= Number(max)
            ),
            msg: 'The value must be less than or equal to ' + max + '.'
        }
    }
};
